import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-head" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  key: 0,
  class: "access-points__update-location"
}
const _hoisted_8 = { class: "access-point__form-footer" }
const _hoisted_9 = {
  type: "submit",
  class: "access-point__add-submit"
}
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ active: _ctx.showAdd }, "add-access-point"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.action) + " Access Point", 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_BaseIcon, { icon: "modal/close" })
      ])
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.upsertAccessPoint && _ctx.upsertAccessPoint(...args)), ["prevent"])),
      class: "form"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseLabel, { title: "Label" }),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accessPointData.label) = $event)),
          class: "base__input",
          type: "text",
          disabled: !_ctx.updateLabel,
          required: ""
        }, null, 8, _hoisted_3), [
          [_vModelText, _ctx.accessPointData.label]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BaseLabel, { title: "Floor" }),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.accessPointData.floorNumber) = $event)),
          class: "base__select",
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateFloorNumber($event))),
          required: ""
        }, [
          _cache[8] || (_cache[8] = _createElementVNode("option", { value: "" }, "Select Floor", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.floorRanges, (num) => {
            return (_openBlock(), _createElementBlock("option", {
              key: num,
              value: num
            }, " Floor " + _toDisplayString(num), 9, _hoisted_5))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.accessPointData.floorNumber]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BaseLabel, { title: "Location" }),
        (_ctx.hasAccessPointCoordinates)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.accessPointCoordinates), 1),
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.updateAccessPointLocation && _ctx.updateAccessPointLocation(...args)))
              }, " UPDATE ")
            ]))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.setAccessPointLocation && _ctx.setAccessPointLocation(...args))),
              class: "access-points__set-location"
            }, [
              _createVNode(_component_BaseIcon, { icon: "access-point/location" }),
              _cache[9] || (_cache[9] = _createElementVNode("span", null, "SET LOCATION", -1))
            ]))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.action) + " Access Point ", 1),
        _createElementVNode("button", {
          disabled: _ctx.disabled,
          type: "button",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "access-point__add-cancel"
        }, " Cancel ", 8, _hoisted_10)
      ])
    ], 32)
  ], 2))
}