import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dashboard__stats" }
const _hoisted_2 = { class: "dashboard__stats__item__see__more" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "dashboard__charts" }
const _hoisted_5 = { class: "dashboard__charts__item" }
const _hoisted_6 = { class: "dashboard__charts__item__head" }
const _hoisted_7 = { class: "dashboard__charts__item__title" }
const _hoisted_8 = { class: "header-3" }
const _hoisted_9 = { class: "dashboard__charts__item__select" }
const _hoisted_10 = { class: "dashboard__item__select_values" }
const _hoisted_11 = { class: "dashboard__charts__line" }
const _hoisted_12 = { class: "dashboard__charts__item" }
const _hoisted_13 = { class: "dashboard__charts__item__head" }
const _hoisted_14 = { class: "dashboard__charts__item__title" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "dashboard__charts__pie" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_MultiLineChart = _resolveComponent("MultiLineChart")!
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_Filters, {
        allowedFilters: _ctx.allowedFilters,
        onApply_filters: _ctx.changeData
      }, null, 8, ["allowedFilters", "onApply_filters"]),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (scope, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: "dashboard__stats__item dashboard__card"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                onClick: ($event: any) => (_ctx.goToDeviceView(scope)),
                class: "dashboard__stats__item__see__more__button"
              }, [
                _cache[0] || (_cache[0] = _createElementVNode("span", null, "See more ", -1)),
                _createVNode(_component_BaseIcon, { icon: "see-more" })
              ], 8, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h3", _hoisted_8, _toDisplayString(scope.name), 1),
                    _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "body-2" }, "Active devices", -1))
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupByList, (group, key) => {
                        return (_openBlock(), _createElementBlock("span", {
                          class: _normalizeClass({ active: group === scope.groupBy }),
                          key: key
                        }, _toDisplayString(group), 3))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_MultiLineChart, {
                    "chart-options": _ctx.lineChartOptions,
                    "chart-data": scope.lineChart,
                    width: 700
                  }, null, 8, ["chart-options", "chart-data"])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("h5", {
                      onClick: ($event: any) => (_ctx.goToDeviceView(scope)),
                      class: "action body-2"
                    }, " Total cost of ownership ", 8, _hoisted_15),
                    _createElementVNode("h3", {
                      onClick: ($event: any) => (_ctx.goToDeviceView(scope)),
                      class: "action header-2"
                    }, _toDisplayString(scope.totalCost), 9, _hoisted_16)
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_DoughnutChart, {
                    "is-filterable": true,
                    onFilter: ($event: any) => (_ctx.addStatusToFilter($event, scope)),
                    chartData: scope.doughnutChart
                  }, null, 8, ["onFilter", "chartData"])
                ])
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}